import './App.css'
import { BrowserRouter as Router } from 'react-router-dom'
import React from 'react'

import ResponsiveDrawer from './components/ResponsiveDrawer'
import Routes from './components/Routes'
import { AuthProvider } from './context/AuthContext'
import { CssBaseline } from '@material-ui/core'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
// import { FetchProvider } from './context/FetchContext';

const customTheme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        'html, body': {
          backgroundColor: '#e12f3b',
          minHeight: '100%'
        },

        'form.formSpacing': {
          '& .MuiFormControl-root': {
            margin: '.5rem 0rem'
          },
          '& .MuiButton-root': {
            marginTop: '.5rem',
            marginRight: '.5rem'
          }
        },

        '.MuiPaper-root.padded': {
          padding: '1rem',
          paddingBottom: '1.5rem'
        }
      }
    }
  }
})

function App() {
  return (
    <>
      <Router>
        <AuthProvider>
          <ThemeProvider theme={customTheme}>
            <CssBaseline />
            <ResponsiveDrawer>
              <Routes />
            </ResponsiveDrawer>
          </ThemeProvider>
        </AuthProvider>
      </Router>
    </>
  )
}

export default App
