import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Paper, Grid, Typography } from '@material-ui/core'
import Loading from '../components/Loading'

const NoticeList = () => {
  const [notices, setNotices] = useState([])

  useEffect(() => {
    axios
      .get('/api/notices/')
      .then((res) => {
        setNotices(res.data)
      })
      .catch((err) => console.log(err))
  }, [])

  const listNotices = () => {
    return notices.map((notice) => {
      return (
        <Grid item key={notice._id}>
          <Paper className="padded">
            <Typography variant="h5">{notice.title}</Typography>
            <Typography variant="subtitle2" gutterBottom>
              {new Date(notice.date).toString().slice(4, 15)}
            </Typography>
            <Typography variant="body1">{notice.content}</Typography>
          </Paper>
        </Grid>
      )
    })
  }

  return (
    <div>
      <Typography variant="h3" gutterBottom style={{ color: 'white' }}>
        Notices
      </Typography>
      <div>
        <Grid container direction="column" spacing={2}>
          {notices.length > 0 ? listNotices() : <Loading />}
        </Grid>
      </div>
    </div>
  )
}

export default NoticeList
