import { useState, useEffect, useCallback } from 'react'
import MuiAlert from '@material-ui/lab/Alert'
import { Collapse, Slide, Snackbar } from '@material-ui/core'

const Alert = (props) => {
  const [visible, setVisible] = useState(false)
  const [severity, setSeverity] = useState('info')
  const [message, setMessage] = useState('')
  const [autoHide, setAutoHide] = useState(null)

  // useEffect(() => {
  // 	if (props.response != null) {
  // 		handleResponse(props.response);
  // 	}
  // }, [props.response, handleResponse])

  const handleResponse = useCallback((response) => {
    if (response === null) return
    setVisible(false)
    // TODO: hacky way to show previous message closing, maybe fix
    setTimeout(() => {
      switch (response.status) {
        case 400:
        case 401:
        case 403:
          setSeverity('error')
          setAutoHide(null)
          if ('errors' in response.data) {
            setMessage(separateErrors(response.data.errors))
          } else {
            setMessage(response.data.message)
          }
          break
        case 200:
          setSeverity('success')
          setAutoHide(5000)
          setMessage(response.data.message)
          // setMessage(successMessage);
          break
        default:
          break
      }
      setVisible(true)
    }, 250)
  }, [])

  useEffect(() => {
    const update = (response) => {
      handleResponse(response)
    }
    update(props.response)
  }, [props.response, handleResponse])

  const separateErrors = (errors = {}) => {
    const errorMessage = Object.entries(errors).map((error) => {
      return <div key={error[1].path}>{error[1].message}</div>
    })
    return errorMessage
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setVisible(false)
  }

  const displayMode = 1
  return (
    <>
      {(displayMode === 0 || displayMode === 1) && (
        <Snackbar open={visible} autoHideDuration={autoHide} onClose={handleClose} TransitionComponent={Slide}>
          <MuiAlert severity={severity} onClose={handleClose}>
            {message}
          </MuiAlert>
        </Snackbar>
      )}

      {(displayMode === 0 || displayMode === 2) && (
        <Collapse in={visible}>
          <MuiAlert severity={severity} style={{ marginTop: '1rem' }} onClose={handleClose}>
            {message}
          </MuiAlert>
        </Collapse>
      )}
    </>
  )
}

export default Alert
