import React, { useState, useContext } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import axios from 'axios'
import { AuthContext } from '../context/AuthContext'
import owl from '../images/academy-logo.png'
import { Button, Card, CardContent, Grid, TextField } from '@material-ui/core'
import Alert from '../components/Alert'

const useStyles = makeStyles((theme) => ({
  loginCard: {
    width: '90%',
    maxWidth: 440,
    minWidth: 250
  },
  fullWidth: {
    width: '100%'
  }
}))

const Login = () => {
  const classes = useStyles()
  const authContext = useContext(AuthContext)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [redirectOnLogin, setRedirectOnLogin] = useState(false)

  const [response, setResponse] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()

    const credentials = {
      email: email.toLowerCase(),
      password
    }

    try {
      const res = await axios.post('/api/users/authenticate', credentials)
      authContext.setAuthState(res.data)
      setTimeout(() => {
        setRedirectOnLogin(true)
      }, 200)
    } catch (error) {
      setResponse(error.response)
      setPassword('')
    }
  }

  return (
    <>
      {redirectOnLogin && <Redirect to="/" />}
      <Grid container justify="center">
        <Card className={classes.loginCard}>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} direction="column" alignItems="center">
                <Grid item>
                  <img src={owl} alt="" className={classes.owlImage} />
                </Grid>
                <Grid item>
                  <Typography variant="h4" align="center" gutterBottom>
                    Log in to your account
                  </Typography>
                </Grid>

                <Grid item className={classes.fullWidth}>
                  <TextField
                    id="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoFocus
                  />
                </Grid>
                <Grid item className={classes.fullWidth}>
                  <TextField
                    id="password"
                    label="Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <Button type="submit" variant="contained" color="primary">
                    Login
                  </Button>
                </Grid>
              </Grid>
              <Alert response={response} />
            </form>
          </CardContent>
        </Card>
      </Grid>
    </>
  )
}

export default Login
