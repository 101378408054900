import React, { useContext } from 'react'
import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { AuthContext } from '../context/AuthContext'

// Material UI imports
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import { Button } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'
import Container from '@material-ui/core/Container'

// Icon imports
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import HomeIcon from '@material-ui/icons/Home'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import EditIcon from '@material-ui/icons/Edit'
import PersonIcon from '@material-ui/icons/Person'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import SchoolIcon from '@material-ui/icons/School'
import AssessmentIcon from '@material-ui/icons/Assessment'
import PostAddIcon from '@material-ui/icons/PostAdd'
import ScheduleIcon from '@material-ui/icons/Schedule'
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import VisibilityIcon from '@material-ui/icons/Visibility'
import TableChartIcon from '@material-ui/icons/TableChart'
import EmailIcon from '@material-ui/icons/Email'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import FunctionsIcon from '@material-ui/icons/Functions'

// Image imports
import owl from '../images/academy-logo.png'

const drawerWidth = 220

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
    // // Removes link decorations from list link
    // '& .MuiList-root > *': {
    //   textDecoration: 'none',
    //   color: 'inherit',
    //   // Sets list link font size
    //   '& .MuiListItemText-root > *': {
    //     fontSize: '.88rem'
    //   },
    // },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    },
    backgroundColor: '#f44336'
  },
  appBarFull: {
    width: '100%',
    marginLeft: 0
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
      fontSize: '.88rem'
    },
    '& .MuiListItemText-root > *': {
      fontSize: '.88rem'
    }
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(2),
    backgroundColor: '#e12f3b',
    '& .MuiContainer-root': {
      minHeight: '82vh'
    }
  },
  nested: {
    paddingLeft: theme.spacing(3)
  },

  // Custom styles
  title: {
    flexGrow: 1
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  },
  owlImage: {
    height: '3rem',
    width: 'auto',
    marginRight: '1rem'
  },
  owlImageBL: {
    position: 'absolute',
    bottom: '1rem',
    left: '1rem',
    height: '4rem',
    width: 'auto'
  }
}))

function ResponsiveDrawer(props) {
  const authContext = useContext(AuthContext)

  const { window } = props
  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = useState(false)
  const [collapseStudents, setCollapseStudents] = useState(true)
  const [collapseReports, setCollapseReports] = useState(true)
  const [collapseTasks, setCollapseTasks] = useState(true)

  const drawerItems = [
    {
      label: 'Students',
      roles: ['tutor', 'parent'],
      icon: <SchoolIcon />,
      collapseState: collapseStudents,
      setCollapseState: setCollapseStudents,
      subitems: [
        {
          label: 'Student Info',
          roles: ['tutor', 'parent'],
          icon: <PersonIcon />,
          route: '/student/info'
        },
        {
          label: 'Add Student',
          icon: <PersonAddIcon />,
          route: '/student/add'
        },
        {
          label: 'Edit Student',
          icon: <EditIcon />,
          route: '/student/edit'
        }
      ]
    },
    {
      label: 'Reports',
      roles: ['tutor'],
      icon: <AssessmentIcon />,
      collapseState: collapseReports,
      setCollapseState: setCollapseReports,
      subitems: [
        {
          label: 'Add Report',
          roles: ['tutor'],
          icon: <PostAddIcon />,
          route: '/report/add'
        },
        {
          label: 'Edit Report',
          icon: <EditIcon />,
          route: '/report/edit'
        },
        {
          label: 'Scheduler',
          icon: <ScheduleIcon />,
          route: '/scheduler'
        },
        {
          label: 'Review/Release',
          icon: <VisibilityIcon />,
          route: '/report/review'
        }
      ]
    },
    {
      label: 'Tasks',
      icon: <SupervisorAccountIcon />,
      collapseState: collapseTasks,
      setCollapseState: setCollapseTasks,
      subitems: [
        {
          label: 'Post Notice',
          icon: <SpeakerNotesIcon />,
          route: '/notice/add'
        },
        {
          label: 'Edit Notice',
          icon: <EditIcon />,
          route: '/notice/edit'
        },
        {
          label: 'Add User',
          icon: <PersonAddIcon />,
          route: '/user/add'
        },
        {
          label: 'Edit User',
          icon: <EditIcon />,
          route: '/user/edit'
        },
        {
          label: 'Email List',
          icon: <EmailIcon />,
          route: '/emaillist'
        },
        {
          label: 'Hours Left',
          icon: <HourglassEmptyIcon />,
          route: '/hoursleft'
        },
        {
          label: 'Admin Functions',
          icon: <FunctionsIcon />,
          route: '/admin'
        }
      ]
    }
  ]

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />

      {/* Home Button */}
      <List>
        <RouterLink key="Home" to="/" tabIndex="-1">
          <ListItem button>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
        </RouterLink>
      </List>

      <Divider />

      <List>
        {authContext.isAuthorized(['admin']) && authContext.isAuthenticated() && (
          <RouterLink key="data-table" to="/data-table" tabIndex="-1">
            <ListItem button>
              <ListItemIcon>
                <TableChartIcon />
              </ListItemIcon>
              <ListItemText primary="Data Table" />
            </ListItem>
          </RouterLink>
        )}

        {/* Iterate through drawer items */}
        {drawerItems.map(({ label, icon, collapseState, setCollapseState, subitems, roles = [] }) => {
          // If the user is authorized to access the route, display it in the drawer
          if (authContext.isAuthorized(['admin', ...roles]) && authContext.isAuthenticated()) {
            return (
              // <List key={label}>
              <div key={label}>
                <ListItem button onClick={() => setCollapseState(!collapseState)}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={label} />
                  {collapseState ? <ExpandLess /> : <ExpandMore />}
                </ListItem>

                <Collapse in={collapseState} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {/* Repeat above logic for the subitems of each drawer item */}
                    {subitems !== undefined &&
                      subitems.map(({ label, icon, route, roles = [] }) => {
                        if (authContext.isAuthorized(['admin', ...roles])) {
                          return (
                            <RouterLink to={route} key={label}>
                              <ListItem button className={classes.nested}>
                                <ListItemIcon>{icon}</ListItemIcon>
                                <ListItemText primary={label} />
                              </ListItem>
                            </RouterLink>
                          )
                        } else {
                          return null
                        }
                      })}
                  </List>
                </Collapse>
              </div>
              // </List>
            )
          } else {
            return null
          }
        })}
      </List>
    </div>
  )

  const container = window !== undefined ? () => window().document.body : undefined

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar
        position="fixed"
        className={`${classes.appBar} ` + (!authContext.isAuthenticated() && classes.appBarFull)}
      >
        <Toolbar>
          {authContext.isAuthenticated() && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          )}
          <img src={owl} alt="" className={classes.owlImage} />
          <Typography variant="h6" className={classes.title} noWrap>
            Academy for Mathematics and English - Tower Hill Learning Center
          </Typography>

          {!authContext.isAuthenticated() ? (
            <RouterLink to="/login" className={classes.link}>
              <Button color="inherit" variant="outlined" endIcon={<AccountCircleIcon />}>
                Login
              </Button>
            </RouterLink>
          ) : (
            <Button color="inherit" onClick={authContext.logout} variant="outlined" endIcon={<AccountCircleIcon />}>
              Logout
            </Button>
          )}
        </Toolbar>
      </AppBar>

      {authContext.isAuthenticated() && (
        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper
              }}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper
              }}
              variant="permanent"
              open
            >
              {drawer}
              {/* <img src={owl} alt="" className={classes.owlImageBL} /> */}
            </Drawer>
          </Hidden>
        </nav>
      )}

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Container>{props.children}</Container>
        {/* <div className="footer"> Travis Welygan &#169; 2021</div> */}
      </main>
    </div>
  )
}

export default ResponsiveDrawer
