import { useContext, lazy, Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import Loading from '../components/Loading'
import Login from '../pages/Login'
import NoticeList from '../pages/NoticeList'
import AuthDebugger from '../pages/AuthDebugger'
// import { Fade } from '@material-ui/core'

const AddNotice = lazy(() => import('../pages/AddNotice'))
const AddPerson = lazy(() => import('../pages/AddPerson'))
const AddReport = lazy(() => import('../pages/AddReport'))
const AddUser = lazy(() => import('../pages/AddUser'))
const DataTable = lazy(() => import('../pages/DataTable'))
const EditNotice = lazy(() => import('../pages/EditNotice'))
const EditReport = lazy(() => import('../pages/EditReport'))
const EditStudent = lazy(() => import('../pages/EditStudent'))
const EditUser = lazy(() => import('../pages/EditUser'))
const EmailList = lazy(() => import('../pages/EmailList'))
const HoursLeft = lazy(() => import('../pages/HoursLeft'))
const ReviewReports = lazy(() => import('../pages/ReviewReports'))
const StudentInfo = lazy(() => import('../pages/StudentInfo'))
const Scheduler = lazy(() => import('../pages/Scheduler'))

const AdminFunctions = lazy(() => import('../pages/AdminFunctions'))

const ProtectedRoute = ({ children, roles = [], ...rest }) => {
  const authContext = useContext(AuthContext)

  return (
    <Route
      {...rest}
      render={() =>
        authContext.isAuthenticated() && authContext.isAuthorized(['admin', ...roles]) ? (
          <>{children}</>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  )
}

const Routes = () => {
  return (
    <>
      <Suspense fallback={<Loading />}>
        <Switch>
          {/* Public Routes */}
          <Route path="/" exact>
            <NoticeList />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/auth">
            <AuthDebugger />
          </Route>

          {/* Admin Routes */}

          <ProtectedRoute path="/student/add">
            <AddPerson />
          </ProtectedRoute>

          <ProtectedRoute path="/notice/add">
            <AddNotice />
          </ProtectedRoute>

          <ProtectedRoute path="/report/add" roles={['tutor']}>
            <AddReport />
          </ProtectedRoute>

          <ProtectedRoute path="/user/add">
            <AddUser />
          </ProtectedRoute>

          <ProtectedRoute path="/data-table">
            <DataTable />
          </ProtectedRoute>

          <ProtectedRoute path="/notice/edit">
            <EditNotice />
          </ProtectedRoute>

          <ProtectedRoute path="/report/edit">
            <EditReport />
          </ProtectedRoute>

          <ProtectedRoute path="/student/edit">
            <EditStudent />
          </ProtectedRoute>

          <ProtectedRoute path="/user/edit">
            <EditUser />
          </ProtectedRoute>

          <ProtectedRoute path="/report/review">
            <ReviewReports />
          </ProtectedRoute>

          <ProtectedRoute path="/scheduler">
            <Scheduler />
          </ProtectedRoute>

          <ProtectedRoute path="/student/info/:id" roles={['tutor', 'parent']}>
            <StudentInfo />
          </ProtectedRoute>

          <ProtectedRoute path="/student/info" roles={['tutor', 'parent']}>
            <StudentInfo />
          </ProtectedRoute>

          <ProtectedRoute path="/emaillist">
            <EmailList />
          </ProtectedRoute>

          <ProtectedRoute path="/hoursleft">
            <HoursLeft />
          </ProtectedRoute>

          <ProtectedRoute path="/admin">
            <AdminFunctions />
          </ProtectedRoute>
        </Switch>
      </Suspense>
    </>
  )
}

export default Routes
