import React, { useContext } from 'react'
import { AuthContext } from '../context/AuthContext'

const AuthStateItem = ({ title, value }) => (
  <div style={{ maxWidth: '400px', overflow: 'auto' }}>
    <p>{title}</p>
    <pre>
      <code>{value}</code>
    </pre>
  </div>
)

const AuthDebugger = () => {
  const authContext = useContext(AuthContext)
  const { token, expiresAt, userInfo } = authContext.authState

  return (
    <section>
      <div>
        <AuthStateItem title="Token" value={token} />
      </div>
      <div>
        <AuthStateItem title="Expiry" value={expiresAt} />
      </div>
      <div>
        <AuthStateItem title="User Info" value={JSON.stringify(userInfo, null, 2)} />
      </div>
    </section>
  )
}

export default AuthDebugger
